import { observer } from 'mobx-react';
import React, { Component, useState } from 'react';
import { runInAction } from "mobx";
import { Link } from "react-router-dom";
import { NavButton, NavButtonPlaceholder } from "./navButton";
import AppStore from "../../stores/appStore";
import common from "../common.module.css";
import styles from "./flightSelectList.module.css";

export const FlightSelectList = observer((props: { subject: string }) => {

	const flights = AppStore.getInstance().flightStore.flights
	const click = function (e: any) {
		let flight = AppStore.getInstance().flightStore.flights.find(f => f.flightId == e.currentTarget.id)
		runInAction(() => AppStore.getInstance().uiStore.setActiveFlight(flight))
	}

	return flights && (<div className={styles.flightListWrapper + ' noPrint'}>
		{flights && flights.map((f) => {
			return <FlightSelectionListItem key={f.flightId} f={f} subject={props.subject}
				onClick={f.paid ? null : click}></FlightSelectionListItem>;
		})}

		{!flights && (<>
			<NavButtonPlaceholder />
			<NavButtonPlaceholder />
			<NavButtonPlaceholder />
		</>
		)}
	</div>)
})

export const FlightSelectionListItem = observer((props: any) => {
	if (!props.f || !props.f.flightInfo || props.f.flightInfo.length === 0 || props.f.subject.toUpperCase() != props.subject.toUpperCase()) return null
	return <NavButton id={props.f.flightId} bg="bg3" heading={props.f.flightInfo[0].toDisplay}
		subheading={props.f.flightInfo[1]?.toDisplay} click={props.onClick} link="/flight" />
})


