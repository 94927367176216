import React from 'react';
import './App.css';
import { observer } from "mobx-react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FlightPage from "./components/pages/flightPage/flightPage";
import { MainMenuPage } from "./components/pages/mainMenuPage/mainMenuPage";
import { WelcomePage } from "./components/pages/welcomePage/welcomePage";
import { IcaoPage } from "./components/pages/icaoPage/icaoPage";
import { GroundSchoolPage } from "./components/pages/groundSchoolPage/groundSchoolPage";
import { NavigationPage } from "./components/pages/navigationPage/navigationPage";
import { HomePage } from "./components/pages/homePage/homePage";
import { ReadbackPage } from "./components/pages/readbackPage/readbackPage";
import { PositionPage } from "./components/pages/positionPage/positionPage";
import { VoicePage } from "./components/pages/voicePage";
import { Header } from "./components/common/header";
import { AccountPage } from "./components/pages/accountPage/accountPage";

const App = () => {
    return (
        < div className="App" >
            <Header></Header>
            <BrowserRouter>
                <Routes>
                    <Route path={"/.auth"} />

                    <Route path="/account" element={<AccountPage />} />

                    <Route path="/welcome" element={<WelcomePage />} />
                    <Route path="/flights" element={<NavigationPage />} />

                    <Route path="/ground/*" element={<GroundSchoolPage />} />

                    <Route path="/flight" element={<FlightPage />} />
                    <Route path="/voices" element={<VoicePage />} />

                    <Route index element={<HomePage />} />
                    <Route path="*" element={<MainMenuPage />} />

                </Routes>
            </BrowserRouter>
        </div >
    )
}

export default observer(App);
