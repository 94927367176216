import AppStore from "./appStore";
import { makeAutoObservable, runInAction } from "mobx";
import { getWaveBlob } from "webm-to-wav-converter";
export enum waveStoreState {
	idle,
	recording,
	waitingForTranscribe
}

export class WaveStore {
	state: waveStoreState = waveStoreState.idle
	wave: any
	recording: any
	mediaRecorder: any
	chunks = []
	allowRecord: boolean = true
	currentinteractionId: any

	constructor(appStore: AppStore) {

		this.wave = []
		navigator.mediaDevices.getUserMedia({ audio: true })
			.then((stream) => {
				this.mediaRecorder = new MediaRecorder(stream, { audioBitsPerSecond: 16000 });
				this.mediaRecorder.ondataavailable = (e) => {
					this.chunks.push(e.data);
					e.data.slice(0, 255).stream().getReader().read().then((data) => runInAction(() => this.setWave(data.value)))
				};
				this.mediaRecorder.onstop = (e) => {
					const webmBlob = new Blob(this.chunks, { type: "audio/webm" });
					getWaveBlob(webmBlob, false, { sampleRate: 16000 }).then((wavBlob) => {
						this.transcribeAudio(this.currentinteractionId, wavBlob).then((result) => {
							runInAction(() => {
								this.state = waveStoreState.idle
								AppStore.getInstance().uiStore.flightPageModel.handleAtxSpeechRecognitionResult(result)
							})
						})
					});
					this.chunks = [];
					runInAction(() => {
						this.state = waveStoreState.waitingForTranscribe
					})

				}
				this.mediaRecorder.onstart = (e) => {
					runInAction(() => {
						this.state = waveStoreState.recording
					})
				}
			})
			.catch((err) => {
				console.error(`The following getUserMedia error occurred: ${err}`);
			});
		makeAutoObservable(this)
	}

	setWave(values) {

		const newWave = []
		for (var i = 0; i < Math.min(values.length, 255); i++)
			newWave.push(values[i])

		runInAction(() => {
			this.wave = newWave
		})

	}

	handlePttUp = (event: any) => {

		if (event.key === 't' && this.state === waveStoreState.recording) {
			//set a delay to make sure we get all the audio
			window.setTimeout(() => {
				this.mediaRecorder.stop()
			}, 400)
		}
	}

	handlePttDown = (event: any) => {
		if (this.allowRecord && event.key === 't' && this.state === waveStoreState.idle && this.mediaRecorder.state !== "recording") {
			this.mediaRecorder.start(100)
		}
	}

	public async transcribeAudio(currentPromptKey: string, blob: Blob): Promise<any> {

		const backendUri: string = process.env.REACT_APP_API_URL + '/api/transcribe';

		const formData = new FormData();
		formData.append('promptKey', currentPromptKey);
		formData.append('language', 'en');
		formData.append('audio', blob);

		const requestOptions
			:
			RequestInit = {
			method: 'POST',
			//headers,
			body: formData,
			credentials: "include"
		};

		const response = await fetch(backendUri, requestOptions);
		return await response.json();
	}
}