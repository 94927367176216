import AppStore from "./appStore";
import { makeAutoObservable, runInAction } from "mobx";

export class IdentData {
	public userId = ""
	public name = "Anonymous"
	public picture = "https://"
	public email: string = ""
	public isAuthenticated: boolean = false
	public daysPaid: number = 0
	constructor() {
		makeAutoObservable(this);
	}
}

export class IdentStore {
	private readonly appStore: AppStore
	public state: "noauth" | "waitingotp" | "authenticated" = "noauth";
	public identData = new IdentData()
	public navigate: any = null;

	constructor(appStore: AppStore) {
		this.appStore = appStore
		makeAutoObservable(this);
	}

	public logOut() {
		this.identData = new IdentData()
		this.state = "noauth"
		//todo call server to log out
	}

	public async requestOtp(phone: string) {
		const backendUri: string = process.env.REACT_APP_API_URL + '/api/requestotp';
		const formData = new FormData();
		formData.append('phone', phone);
		const requestOptions
			:
			RequestInit = {
			method: 'POST',
			body: formData,
			credentials: "include"
		};
		const response = await fetch(backendUri, requestOptions)
		const data = await response.json()

		if (data.status === "ok") {
			runInAction(() => {
				this.state = "waitingotp"
			})

		} else {
			runInAction(() => {
				this.identData = new IdentData()
				this.state = "noauth"
			})
		}

		return await response.text;

	}

	public async validateOtp(otp: string) {
		const backendUri: string = process.env.REACT_APP_API_URL + '/api/validateotp';
		const formData = new FormData();
		formData.append('otp', otp);
		const requestOptions
			:
			RequestInit = {
			method: 'POST',
			body: formData,
			credentials: "include"
		};
		const response = await fetch(backendUri, requestOptions);
		const data = await response.json();
		if (data.status === "ok") {
			if (data.paidUntil === null) { this.identData.daysPaid = 0 }
			else if (data.paidUntil < new Date().toISOString()) { this.identData.daysPaid = 0 }
			else {
				const date1 = new Date(data.paidUntil);
				const date2 = new Date();
				this.identData.daysPaid = this.dateDiffInDays(date2, date1)
			}

			runInAction(() => {
				this.identData.userId = data.userId
				this.identData.name = data.name
				this.identData.email = data.email
				this.identData.isAuthenticated = true
				this.state = "authenticated"
			})
			return true
		}
		else {
			runInAction(() => {
				this.identData = new IdentData()
				this.state = "noauth"
			})
		}
		return false
	}

	// a and b are javascript Date objects
	private dateDiffInDays(a, b) {
		const _MS_PER_DAY = 1000 * 60 * 60 * 24;
		// Discard the time and time-zone information.
		const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

		return Math.floor((utc2 - utc1) / _MS_PER_DAY);
	}
}